.logo_back{
    background-color:#4C0404;
    height: 64px;
    width: 64px;
    position: absolute;
    top: 28px;
    left: 24px;
    border-radius: 12px;
    transition: 0.6s;
}
@media only screen and (max-width:768px) {
	.logo_back{
        height: 58px;
        width: 58px;
        top: 25px;
    }
}
.retratos_wrapper{
    width: 100%;
    min-height:100px;
    position: relative;
    left:50%;
    transform: translate(-50%);
    display: flex;
    flex-wrap:wrap;
    height:min-content;
}
.retratos_wrapper_home{
    width: calc(100% - 6px);
    min-height:100px;
    position: relative;
    left:50%;
    transform: translate(-50%);
    height:min-content;
    display: flex;
    flex-wrap: wrap;
    margin: 0 3px;
}
.retratos_wrapper_home .retrato_container_home{
    width: calc(50% - 3px);
    display: flex;
    flex-direction: column;
    height: auto;
}
@media only screen and (max-width:768px) {
	.retratos_wrapper_home .retrato_container_home{
        width: calc(100% - 3px);
    }
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home{
    width: 100%;
    height: 80vh;
    overflow:hidden;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: black;
    cursor: pointer;
    transition: 0.6s;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home_hl:nth-child(1){
    height: 65vh;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home:hover{
    background-size: 110%;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home h3,h4{
    position: absolute;
    z-index: 10;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home h3{
    text-align: center;
    width: 100%;
    line-height: 80vh;
    color: white;
    font-size: 2.5em;
    transition: 0.6s;
    opacity: 0;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home h4{
    color: white;
    font-size: 1.2em;
    left: 15px;
    top: 15px;
    font-weight: normal;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home h3:hover{
    opacity: 1;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home .retrato_content_home_overlay{
    width: 100%;
    min-height: 80vh;
    background-color: black;
    position: absolute;
    opacity: 0;
    transition: 0.6s;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home .retrato_content_home_overlay:hover{
    opacity: 0.8;
}
.retratos_wrapper_home .retrato_container_home .retrato_content_home .retrato_content_home_overlay{
    width: 100%;
    height: auto;
}
@media only screen and (min-width:1550px) {
	.retratos_wrapper_home .retrato_container_home .retrato_content_home{
        height: 120vh;
    }
    .retratos_wrapper_home .retrato_container_home .retrato_content_home_hl:nth-child(1){
        height: 80vh;
    }
    .retratos_wrapper_home .retrato_container_home .retrato_content_home .retrato_content_home_overlay{
        height: 120vh;
    }
}

.retratos_wrapper .retrato_container{
    width: 50%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0;
    padding: 0;
    transition: 0.6s;
    background-color: #222;
}
.retratos_wrapper .retrato_container:hover{
    background-size: 120%;    
}
.retratos_wrapper .retrato_container:nth-child(odd){
    transform: translate(0, -50%);
}
.retratos_wrapper .retrato_container:first-child{
    height: 250px;
    transform: translate(0);
}
.retrato_content{
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    padding: 15px;
    cursor: pointer;
    opacity: 0;
    transition: 0.6s;
}
.retrato_content:hover{
    opacity: 1;
}
.retrato_content h3,h4{
    color: white;
}
.retratos_wrapper .retrato_container .retrato_content h3{
    position: relative;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: lighter !important;
    font-size: 30px;
}

@media only screen and (max-width:768px) {
	.retratos_wrapper .retrato_container{
        width: 100%;
        background-size: cover;
    }.retratos_wrapper .retrato_container:first-child{
        height: 500px;
    }
    .retratos_wrapper .retrato_container:nth-child(odd){
        transform: translate(0);
    }
}
.modal_bob_container{
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    display: none;
}
.modal_bob{
    background-color: rgba(0, 0, 0, 0.8);
    width: 90%;
    height: 90vh;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}
.modal_bob_container .modal_bob img{
    max-height: 90vh;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
}
.modal_bob_container .modal_bob .xclose{
    color: white;
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
}
.modal_bob_container .modal_bob .xclose:hover{
    color: lightgray;
}
.chev{
    color:white;
    position: absolute;
    top:50%;
    font-size: 40px;
    z-index: 3;
    cursor: pointer;
}
.chev:hover{
    color: lightgray;
}
.chev:nth-child(2){
    right: 0;
}
.modal_bob_container .modal_bob h3{
    position: absolute;
    z-index: 4;
    top: 0;
    color: white;
    padding: 8px 15px;
    font-size: 18px;
}
@media only screen and (max-width:768px) {
	.chev{
        bottom: 15px;
        top: auto;
    }
    .modal_bob_container .modal_bob h3{
        top: auto;
        bottom: -5px;
        text-align: center;
        left: 50%;
        transform: translate(-50%);
    }
}
.modal_bob_container .clickout{
    background-color: red;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0;
}
.palco_wrapper{
    width: 90%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 35px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    max-width: 1024px;
}
.palco_wrapper .container_peca{
    width:calc(33.3333% - 8px);
    height:auto;
    padding: 0;
}
.palco_wrapper .container_peca .palco_img{
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    min-height: 240px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.palco_wrapper .container_peca h4{
    margin: 5px 0 !important;
    padding: 0 !important;
    transform: translate(0 , -5px);
    color: #4C0404;
    text-align: center;
    text-align: center !important;
    width:calc(33.3333% - 8px);
}
.palco_wrapper .container_peca .palco_img .palco_overlay{
    background-color: rgba(0,0,0,0.8);
    color: white;
    text-align: center;
    font-size: 38px;
    font-weight: 600;
    height: 240px;
    line-height: 240px;
    opacity: 0;
    transition: 0.6s;
}
.palco_wrapper .container_peca .palco_img .palco_overlay:hover{
    opacity: 1;
}

.palco_single_wrapper{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% - 80px);
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
}
.palco_single_img{
    width: 25%;
    height: 15vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
}
.palco_single_wrapper .palco_single_img .palco_single_content{
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.palco_single_wrapper .palco_single_img .palco_single_content h3{
    color: white;
    font-size: 30px;
    transform: translate(0, 30%);
}
.palco_single_wrapper .palco_single_img .palco_single_content:hover{
    opacity: 1;
}

@media only screen and (max-width:1024px) {
    .palco_single_img{
        width: 33.333%;
        height: 30vw;
    }
}
@media only screen and (max-width:768px) {
    .palco_wrapper .container_peca{
        width:calc(50% - 6px);
    }
    .palco_single_img{
        width: 50%;
        height: 30vw;
    }
    .palco_wrapper .container_peca h4{
        width:calc(50% - 8px);
    }
}
@media only screen and (max-width:590px) {
    .palco_wrapper .container_peca{
        width:100%;
    }
    .palco_single_img{
        width: 100%;
        height: 70vw;
    }
    .palco_wrapper .container_peca h4{
        width:100%;
    }
}
.capa_palco_single{
    width: calc(100% - 80px);
    height: 400px;
    position: relative;
    top: -30px;
    margin: 0 40px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.texto_palco{
    width: calc(100% - 80px);
    margin: 0 40px;
}
.paginator{
    text-align: center;
    width: 96%;
    max-width: 450px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 100px;
}
.paginator .paginator_item{
    cursor: pointer;
    font-family: "montserrat-regular";
    margin: 0 20px;
}

.paginator .atual{
    background-color: #4C0404;
    padding: 0 6px;
    color: white;
}
@media only screen and (max-width:590px) {
    .capa_palco_single{
        width: 100%;
        margin: 0 0px;
    }
    .palco_single_wrapper{
        width: 100%;
    }
}
.admin_container{
    background-color: lightgray;
    width: 100%;
    min-height: 600px;
    text-align: center;
    padding: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.admin_container .painel_controle{
    width: 100%;
}
.admin_container .painel_controle ul{
    list-style: none;
    width: 100%;
    max-width: 500px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    margin: 0 0 40px;
}
.admin_container .painel_controle ul li{
    width: 100%;
    height: 50px;
    background-color: #4f4f4f;
    color: white;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    font-family: "montserrat-regular";
    border-bottom: white solid 1pt;
}
.admin_container .painel_controle ul li:hover{
    background-color: #4C0404;
}
.admin_container form{
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin_container form input{
    width: 100%;
    font-size: 20px;
}
.admin_container form label{
    width: 100%;
}
.admin_container form input[type=file]{
    display: none;
}
.admin_container form .img_select{
    background-color: #4C0404;
    height: auto;
    width: 166px;
    transform: translate(-2px);
    cursor: pointer;
    color: white;
    font-size: 14px;
    line-height: 17px;
    padding:10px;
    text-transform: uppercase;
    margin-bottom: 80px;
}

.admin_container h3{
    font-size: 15px;
    cursor: pointer;
}
.admin_container .edit_wraper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
}
.admin_container .edit_wraper .edit_container{
    width: 100%;
    max-width: 350px;
    margin-bottom: 25px;
}
.admin_container .edit_wraper .edit_container .edit_img{
    height: 25vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.admin_container .edit_wraper .edit_container h4{
    background-color: #222;
}
.admin_container .edit_wraper .edit_container .edit_control{
    display: flex;
    justify-content: space-around;
    font-family: montserrat;
    font-weight: bold;
}
.admin_container .edit_wraper .edit_container .edit_control span{
    cursor: pointer;
}
.admin_container .container_cadTestemonial{
    background-color: #222;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin_container .container_cadTestemonial .cadTestemonial{
    background-color: #222;
    display: flex;
    flex-wrap: wrap;
}
.admin_container .container_cadTestemonial .cadTestemonial span{
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    width: 100%;
}
.admin_container .container_cadTestemonial .cadTestemonial textarea{
    background-color: white;
    padding: 5px;
    margin: 5px;
    font-size: 0.8em;
    width: calc(100% - 10px);
}
.admin_container .container_cadTestemonial .cadTestemonial input{
    background-color: white;
    padding: 5px;
    margin: 5px;
    font-size: 0.8em;
    width: calc(50% - 10px);
}
.admin_container .container_cadTestemonial .cadTestemonial label{
    background-color:#4f4f4f;
    padding: 5px;
    margin: 5px;
    line-height: 50px;
    font-size: 0.8em;
    width: calc(50% - 10px);
    color: white;
    cursor: pointer;
}
.admin_container .container_cadTestemonial .cadTestemonial input[type=file]{
    display: none;
}
.admin_container .container_cadTestemonial .cadTestemonial button{
    margin: 5px;
    width: calc(50% - 10px);
    height: 60px;
}
.edit_mini_images{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.edit_mini_images .mini_image{
    width: calc(100% / 6);
    height: 200px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.edit_mini_images .mini_image span{
    position: absolute;
    left: 10px;
    top: 5px;
    color: red;cursor: pointer;
    background-color: #222;
    padding: 5px;
    font-family: montserrat;
}
@media only screen and (max-width:1540px) {
    .edit_mini_images .mini_image{
        width: calc(100% / 5);
    }
}
@media only screen and (max-width:1200px) {
    .edit_mini_images .mini_image{
        width: calc(100% / 4);
    }
}
@media only screen and (max-width:940px) {
    .edit_mini_images .mini_image{
        width: calc(100% / 3);
    }
}
@media only screen and (max-width:748px) {
    .edit_mini_images .mini_image{
        width: calc(100% / 2);
    }
}
@media only screen and (max-width:600px) {
    .edit_mini_images .mini_image{
        width: 100%;
        height: 250px;
    }
}
.editor_style{
    background-color: #eee;
    margin: 0 0 30px;
    width: 100%;
    height: 350px;
    overflow: auto;
}
.txt_peca{
    overflow: auto;
    max-height: 200px;
    background-color: white;
    padding: 0 5px;
}
.text_editor_container{
    width: 100%;
    max-width: 650px;
    position: relative;
    left: 50%;
    top: 50px;
    transform: translate(-50%);
}
.sobre{
    background-color: #eee;
    padding-top: 100px;
}
.visible i{
    font-size: 25px;
}
.login_container{
    background-color: white;
    text-align: center;
    padding: 50px 0;
}
.login_container form{
    position: relative;
    padding: 50px 0;
}
.login_container form input{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 450px;
}
.excessao{
    font-family: "librebaskerville-regular", serif;
	font-size: 1.8rem;
	text-transform: none;
	letter-spacing: 0;
	color: #FFFFFF;
	margin-left: .3rem;
	cursor: pointer;
}
.capa_amargem{
    width: calc(100% - 80px);
    height: 300px;
    position: relative;
    top: -30px;
    margin: 0 40px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}
.texto_amargem{
    width: calc(100% - 80px);
    margin: 0 40px;
    display: flex;
    flex-wrap: wrap;
}
.testemonials_icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
.testemonials_icons .testemonials_icons_single{
    display: flex;
}
.testemonials_icons .testemonials_icons_single i{
    position: absolute;
    color: red;
    cursor: pointer;
    margin-left: 5px;
}
.testemonials_icons .testemonials_icons_single img{
    height: 60px;
    width: 60px;
}
.testemonials_container{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    margin: 40px;
}
.testemonials_container .testemonial_single{
    width: calc(100% / 3 - 16px);
    background-color: #ddd;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 50px;
    max-height: 650px;
}
.testemonials_container .testemonial_single .testemonial_single_img{
    width: 110px;
    height: 110px;
    align-self: center;
    position: relative;
    transform: translate(0, -50%);
    border-radius: 55px;
    border: #eee solid 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.testemonials_container .testemonial_single .testemonial_single_img img{
    width: 92px;
    height: 92px;
    border-radius: 46px;
}
.testemonials_container .testemonial_single p{
    width: calc(100% - 30px);
    margin: 0 15px;
    text-align: justify;
    position: relative;
    transform: translate(0, -35px);
    max-height: 550px;
    overflow: auto;
}
.testemonials_container .testemonial_single p span{
    color: #bbb;
    font-size: 1.6em;
}
.testemonials_container .testemonial_single p i:nth-child(2){
    color: #bbb;
    font-size: 1.6em;
    transform: rotate(180deg);
}
.testemonials_container .testemonial_single h6{
    text-align: center;
    margin: 0 15px 20px;
    color: #792b2b;
}
.testemonials_container .testemonial_single h4{
    text-align: center;
    margin: 0 15px 20px;
    color: #4f4f4f;
    position: relative;
    top: -30px;
}
.testemonials_container .testemonial_single h6 span{
    text-decoration: none;
    margin-left: 5px;
    font-style: normal;
    font-family: 'Times New Roman', Times, serif;
    color: #bbb;
    text-transform: capitalize;
}
@media only screen and (max-width:1200px) {
    .testemonials_container .testemonial_single{
        width: calc(100% / 2 - 24px);
    }
}
@media only screen and (max-width:940px) {
    .testemonials_container .testemonial_single{
        width: 100%;
    }
    .testemonials_container{
        margin: 40px 15px;
    }
}

