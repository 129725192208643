/* ===================================================================
 *  02. base style overrides - (_document-setup)
 *
 * ------------------------------------------------------------------- */
html {
	font-size: 10px;
}
@media only screen and (max-width:1024px) {
	html {
		font-size: 9.375px;
	}
}
@media only screen and (max-width:768px) {
	html {
		font-size: 10px;
	}
}
@media only screen and (max-width:400px) {
	html {
		font-size: 9.375px;
	}
}

html,
body {
	height: 100%;
}

body {
	background: #050505;
	font-family: "librebaskerville-regular", serif;
	font-size: 1.6rem;
	line-height: 3rem;
	color: #353535;
	margin: 0;
	padding: 0;
}

/* ------------------------------------------------------------------- 
 * links - (_document-setup) 
 * ------------------------------------------------------------------- */
a,
a:visited {
	color: #C77272;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
	color: #000000;
}

/* ===================================================================
 *  03. typography & general theme styles - (_document-setup.scss) 
 *
 * ------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, .h01, .h02, .h03, .h04, .h05, .h06 {
	font-family: "montserrat-bold", sans-serif;
	color: #151515;
	font-style: normal;
	text-rendering: optimizeLegibility;
	margin-bottom: 2.1rem;
}

h3, .h03, h4, .h04 {
	margin-bottom: 1.8rem;
}

h5, .h05, h6, .h06 {
	margin-bottom: 1.2rem;
}

h1, .h01 {
	font-size: 3.1rem;
	line-height: 1.35;
	letter-spacing: -.1rem;
}

@media only screen and (max-width:600px) {
	h1, .h01 {
		font-size: 2.6rem;
		letter-spacing: -.07rem;
	}
}

h2, .h02 {
	font-size: 2.4rem;
	line-height: 1.25;
}
h3, .h03 {
	font-size: 2rem;
	line-height: 1.5;
}
h4, .h04 {
	font-size: 1.7rem;
	line-height: 1.76;
}
h5, .h05 {
	font-size: 1.4rem;
	line-height: 1.7;
	text-transform: uppercase;
	letter-spacing: .2rem;
}
h6, .h06 {
	font-size: 1.3rem;
	line-height: 1.85;
	text-transform: uppercase;
	letter-spacing: .2rem;
}

p img {
	margin: 0;
}

p.lead {
	font-family: "montserrat-regular", sans-serif;
	font-size: 2rem;
	line-height: 1.8;
	color: #4f4f4f;
	margin-bottom: 3.6rem;
}

@media only screen and (max-width:768px) {
	p.lead {
		font-size: 1.8rem;
	}

}

em, i, strong, b {
	font-size: 1.6rem;
	line-height: 3rem;
	font-style: normal;
	font-weight: normal;
}
em, i {
	font-family: "librebaskerville-italic", serif;
}
strong, b {
	font-family: "librebaskerville-bold", serif;
}

small {
	font-size: 1.1rem;
	line-height: inherit;
}

blockquote {
	margin: 3rem 0;
	padding-left: 5rem;
	position: relative;
}

blockquote:before {
	content: "\201C";
	font-size: 10rem;
	line-height: 0px;
	margin: 0;
	color: rgba(0, 0, 0, 0.3);
	font-family: arial, sans-serif;
	position: absolute;
	top: 3.6rem;
	left: 0;
}

blockquote p {
	font-family: "librebaskerville-italic", georgia, serif;
	font-style: italic;
	padding: 0;
	font-size: 1.9rem;
	line-height: 1.895;
}

blockquote cite {
	display: block;
	font-size: 1.3rem;
	font-style: normal;
	line-height: 1.615;
}

blockquote cite:before {
	content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
	color: #4f4f4f;
	border: none;
}

abbr {
	font-family: "librebaskerville-bold", serif;
	font-variant: small-caps;
	text-transform: lowercase;
	letter-spacing: .05rem;
	color: #4f4f4f;
}

var, kbd, samp, code, pre {
	font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

pre {
	padding: 2.4rem 3rem 3rem;
	background: #F1F1F1;
}

code {
	font-size: 1.4rem;
	margin: 0 .2rem;
	padding: .3rem .6rem;
	white-space: nowrap;
	background: #F1F1F1;
	border: 1px solid #E1E1E1;
	border-radius: 3px;
}

pre > code {
	display: block;
	white-space: pre;
	line-height: 2;
	padding: 0;
	margin: 0;
}

pre.prettyprint > code {
	border: none;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

mark {
	background: #FFF49B;
	color: #000;
}

hr {
	border: solid #d2d2d2;
	border-width: 1px 0 0;
	clear: both;
	margin: 2.4rem 0 1.5rem;
	height: 0;
}

/* ------------------------------------------------------------------- 
 * Lists - (_document-setup.scss)  
 * ------------------------------------------------------------------- */
ol {
	list-style: decimal;
}
ul {
	list-style: disc;
}
li {
	display: list-item;
}
ol, ul {
	margin-left: 1.7rem;
}
ul li {
	padding-left: .4rem;
}
ul ul, ul ol, ol ol, ol ul {
	margin: .6rem 0 .6rem 1.7rem;
}

ul.disc li {
	display: list-item;
	list-style: none;
	padding: 0 0 0 .8rem;
	position: relative;
}

ul.disc li::before {
	content: "";
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #4C0404;
	position: absolute;
	left: -17px;
	top: 11px;
	vertical-align: middle;
}

dt {
	margin: 0;
	color: #4C0404;
}

dd {
	margin: 0 0 0 2rem;
}

/* ------------------------------------------------------------------- 
 * Spacing - (_document-setup)  
 * ------------------------------------------------------------------- */
button,
.button {
	margin-bottom: 1.2;
}

fieldset {
	margin-bottom: 1.5rem;
}

input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.fluid-video-wrapper,
.ss-custom-select {
	margin-bottom: 3rem;
}

/* ------------------------------------------------------------------- 
 * floated image - (_document-setup)  
 * ------------------------------------------------------------------- */
img.pull-right {
	margin: 1.5rem 0 0 3rem;
}

img.pull-left {
	margin: 1.5rem 3rem 0 0;
}

/* ------------------------------------------------------------------- 
 * block grids paddings
 * ------------------------------------------------------------------- */
.bgrid {
	padding: 0 20px;
}

@media only screen and (max-width:1024px) {
	.bgrid {
		padding: 0 18px;
	}
}

@media only screen and (max-width:768px) {
	.bgrid {
		padding: 0 15px;
	}
}

@media only screen and (max-width:600px) {
	.bgrid {
		padding: 0 10px;
	}
}

@media only screen and (max-width:400px) {
	.bgrid {
		padding: 0;
	}
}

/* ------------------------------------------------------------------- 
 * tables - (_document-setup.scss)  
 * ------------------------------------------------------------------- */
table {
	border-width: 0;
	width: 100%;
	max-width: 100%;
	font-family: "librebaskerville-regular", sans-serif;
}

th, td {
	padding: 1.5rem 3rem;
	text-align: left;
	border-bottom: 1px solid #E8E8E8;
}
th {
	color: #151515;
	font-family: "montserrat-bold", sans-serif;
}
td {
	line-height: 1.5;
}
th:first-child, td:first-child {
	padding-left: 0;
}
th:last-child, td:last-child {
	padding-right: 0;
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

/* ------------------------------------------------------------------- 
 * pace.js styles - minimal  - (_document-setup.scss)
 * ------------------------------------------------------------------- */
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #FFFFFF;
	position: fixed;
	z-index: 900;
	top: 0;
	right: 100%;
	width: 100%;
	height: 6px;
}


/* ===================================================================
 *  04. preloader - (_preloader-1.scss)
 *
 * ------------------------------------------------------------------- */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #050505;
	z-index: 800;
	height: 100%;
	width: 100%;
}

.no-js #preloader,
.oldie #preloader {
	display: none;
}

#loader {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	padding: 0;
}

#loader:before {
	content: "";
	border-top: 6px solid rgba(255, 255, 255, 0.1);
	border-right: 6px solid rgba(255, 255, 255, 0.1);
	border-bottom: 6px solid rgba(255, 255, 255, 0.1);
	border-left: 6px solid #FFFFFF;
	-webkit-animation: load 1.1s infinite linear;
	animation: load 1.1s infinite linear;
	display: block;
	border-radius: 50%;
	width: 60px;
	height: 60px;
}

@-webkit-keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}

}

@keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}

}


/* ===================================================================
 *  04. forms - (_forms.scss)
 *
 * ------------------------------------------------------------------- */
fieldset {
	border: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	display: block;
	height: 6rem;
	padding: 1.5rem 0;
	border: 0;
	outline: none;
	vertical-align: middle;
	color: #313131;
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.5rem;
	line-height: 3rem;
	max-width: 100%;
	background: transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.ss-custom-select {
	position: relative;
	padding: 0;
}

.ss-custom-select select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	text-indent: 0.01px;
	text-overflow: '';
	margin: 0;
	line-height: 3rem;
	vertical-align: middle;
}

.ss-custom-select select option {
	padding-left: 2rem;
	padding-right: 2rem;
}

.ss-custom-select select::-ms-expand {
	display: none;
}

.ss-custom-select::after {
	content: '\f0d7';
	font-family: 'FontAwesome';
	position: absolute;
	top: 50%;
	right: 1.5rem;
	margin-top: -10px;
	bottom: auto;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 18px;
	text-align: center;
	pointer-events: none;
	color: #252525;
}

/* IE9 and below */
.oldie .ss-custom-select::after {
	display: none;
}

textarea {
	min-height: 25rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
	color: #1C0101;
	border-bottom: 1px solid #4C0404;
}

label,
legend {
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.4rem;
	margin-bottom: .6rem;
	color: #020202;
	display: block;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
}

label > .label-text {
	display: inline-block;
	margin-left: 1rem;
	font-family: "montserrat-regular", sans-serif;
	line-height: inherit;
}

label > input[type="checkbox"],
label > input[type="radio"] {
	margin: 0;
	position: relative;
	top: .15rem;
}

/* -----------------------------------------------------------
 * Style Placeholder Text  
 * ----------------------------------------------------------- */
::-webkit-input-placeholder {
	color: #828282;
}

:-moz-placeholder {
	color: #828282;  /* Firefox 18- */
}

::-moz-placeholder {
	color: #828282;  /* Firefox 19+ */
}

:-ms-input-placeholder {
	color: #828282;
}

.placeholder {
	color: #828282 !important;
}

/* -----------------------------------------------------------
 * Change Autocomplete styles in Chrome
 * ----------------------------------------------------------- */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	-webkit-text-fill-color: #7C0808;
	transition: background-color 5000s ease-in-out 0s;
}


/* ===================================================================
 *  05.  buttons - (_button-essentials.scss)
 *
 * ------------------------------------------------------------------- */
.button,
a.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
	display: inline-block;
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.4rem;
	text-transform: uppercase;
	letter-spacing: .3rem;
	height: 5.4rem;
	line-height: 5.4rem;
	padding: 0 3rem;
	margin: 0 .3rem 1.2rem 0;
	background: #808080;
	color: #222222;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
	border: none;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.button:hover,
a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
	background: #737373;
	color: #000000;
	outline: 0;
}

.button.button-primary,
a.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
	background: #151515;
	color: #FFFFFF;
}

.button.button-primary:hover,
a.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
	background: #030303;
}

button.full-width,
.button.full-width {
	width: 100%;
	margin-right: 0;
}

button.medium,
.button.medium {
	height: 5.7rem !important;
	line-height: 5.7rem !important;
	padding: 0 1.8rem !important;
}

button.large,
.button.large {
	height: 6rem !important;
	line-height: 6rem !important;
	padding: 0rem 3rem !important;
}

button.stroke,
.button.stroke {
	background: transparent !important;
	border: 3px solid #313131;
	line-height: 4.8rem;
}

button.stroke.medium,
.button.stroke.medium {
	line-height: 5.1rem !important;
}

button.stroke.large,
.button.stroke.large {
	line-height: 5.4rem !important;
}

button.stroke:hover,
.button.stroke:hover {
	border: 3px solid #4C0404;
	color: #4C0404;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}


/* ===================================================================
 *  06. other components - (_others.scss)
 *
 * ------------------------------------------------------------------- */ 

/* -------------------------------------------------------------------
 * alert box - (_alert-box.scss)
 * ------------------------------------------------------------------- */
.alert-box {
	padding: 2.1rem 4rem 2.1rem 3rem;
	position: relative;
	margin-bottom: 3rem;
	border-radius: 3px;
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.5rem;
}

.alert-box .close {
	position: absolute;
	right: 1.8rem;
	top: 1.8rem;
	cursor: pointer;
}

.ss-error {
	background-color: #ffd1d2;
	color: #e65153;
}

.ss-success {
	background-color: #c8e675;
	color: #758c36;
}

.ss-info {
	background-color: #d7ecfb;
	color: #4a95cc;
}

.ss-notice {
	background-color: #fff099;
	color: #bba31b;
}

/* ------------------------------------------------------------------- 
 * additional typo styles - (_additional-typo.scss)
 * ------------------------------------------------------------------- */

/* drop cap 
 * ----------------------------------------------- */
.drop-cap:first-letter {
	float: left;
	margin: 0;
	padding: 1.5rem .6rem 0 0;
	font-size: 8.4rem;
	font-family: "montserrat-bold", sans-serif;
	line-height: 6rem;
	text-indent: 0;
	background: transparent;
	color: #151515;
}

/* line definition style 
 * ----------------------------------------------- */
.lining dt,
.lining dd {
	display: inline;
	margin: 0;
}

.lining dt + dt:before,
.lining dd + dt:before {
	content: "\A";
	white-space: pre;
}

.lining dd + dd:before {
	content: ", ";
}

.lining dd + dd:before {
	content: ", ";
}

.lining dd:before {
	content: ": ";
	margin-left: -0.2em;
}

/* dictionary definition style 
 * ----------------------------------------------- */
.dictionary-style dt {
	display: inline;
	counter-reset: definitions;
}

.dictionary-style dt + dt:before {
	content: ", ";
	margin-left: -0.2em;
}

.dictionary-style dd {
	display: block;
	counter-increment: definitions;
}

.dictionary-style dd:before {
	content: counter(definitions, decimal) ". ";
}

/** 
 * Pull Quotes
 * -----------
 * markup:
 *
 * <aside class="pull-quote">
 *		<blockquote>
 *			<p></p>
 *		</blockquote>
 *	</aside>
 *
 * --------------------------------------------------------------------- */
.pull-quote {
	position: relative;
	padding: 2.1rem 3rem 2.1rem 0px;
}

.pull-quote:before,
.pull-quote:after {
	height: 1em;
	position: absolute;
	font-size: 10rem;
	font-family: Arial, Sans-Serif;
	color: rgba(0, 0, 0, 0.3);
}

.pull-quote:before {
	content: "\201C";
	top: 33px;
	left: 0;
}

.pull-quote:after {
	content: '\201D';
	bottom: -33px;
	right: 0;
}

.pull-quote blockquote {
	margin: 0;
}

.pull-quote blockquote:before {
	content: none;
}

/** 
 * Stats Tab
 * ---------
 * markup:
 *
 * <ul class="stats-tabs">
 *		<li><a href="#">[value]<em>[name]</em></a></li>
 *	</ul>
 *
 * Extend this object into your markup.
 *
 * --------------------------------------------------------------------- */
.stats-tabs {
	padding: 0;
	margin: 3rem 0;
}

.stats-tabs li {
	display: inline-block;
	margin: 0 1.5rem 3rem 0;
	padding: 0 1.5rem 0 0;
	border-right: 1px solid #ccc;
}

.stats-tabs li:last-child {
	margin: 0;
	padding: 0;
	border: none;
}

.stats-tabs li a {
	display: inline-block;
	font-size: 2.5rem;
	font-family: "montserrat-bold", sans-serif;
	border: none;
	color: #252525;
}

.stats-tabs li a:hover {
	color: #4C0404;
}

.stats-tabs li a em {
	display: block;
	margin: .6rem 0 0 0;
	font-size: 1.4rem;
	font-family: "montserrat-regular", sans-serif;
	color: #4f4f4f;
}

/**
 * skillbars - (_skillbars.scss)
 * -------------------------------------------------------------------
 */
.skill-bars {
	list-style: none;
	margin: 6rem 0 3rem;
}

.skill-bars li {
	height: .6rem;
	background: #a1a1a1;
	width: 100%;
	margin-bottom: 6rem;
	padding: 0;
	position: relative;
}

.skill-bars li strong {
	position: absolute;
	left: 0;
	top: -3rem;
	font-family: "montserrat-bold", sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: .2rem;
	font-size: 1.5rem;
	line-height: 2.4rem;
}

.skill-bars li .progress {
	background: #313131;
	position: relative;
	height: 100%;
}

.skill-bars li .progress span {
	position: absolute;
	right: 0;
	top: -3.6rem;
	display: block;
	font-family: "montserrat-regular", sans-serif;
	color: white;
	font-size: 1.1rem;
	line-height: 1;
	background: #313131;
	padding: .6rem .6rem;
	border-radius: 3px;
}

.skill-bars li .progress span::after {
	position: absolute;
	left: 50%;
	bottom: -5px;
	margin-left: -5px;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	border-top: 5px solid #313131;
	content: "";
}

.skill-bars li .percent5 {
	width: 5%;
}

.skill-bars li .percent10 {
	width: 10%;
}

.skill-bars li .percent15 {
	width: 15%;
}

.skill-bars li .percent20 {
	width: 20%;
}

.skill-bars li .percent25 {
	width: 25%;
}

.skill-bars li .percent30 {
	width: 30%;
}

.skill-bars li .percent35 {
	width: 35%;
}

.skill-bars li .percent40 {
	width: 40%;
}

.skill-bars li .percent45 {
	width: 45%;
}

.skill-bars li .percent50 {
	width: 50%;
}

.skill-bars li .percent55 {
	width: 55%;
}

.skill-bars li .percent60 {
	width: 60%;
}

.skill-bars li .percent65 {
	width: 65%;
}

.skill-bars li .percent70 {
	width: 70%;
}

.skill-bars li .percent75 {
	width: 75%;
}

.skill-bars li .percent80 {
	width: 80%;
}

.skill-bars li .percent85 {
	width: 85%;
}

.skill-bars li .percent90 {
	width: 90%;
}

.skill-bars li .percent95 {
	width: 95%;
}

.skill-bars li .percent100 {
	width: 100%;
}

/* ===================================================================
 *  07. common and reusable styles 
 *
 * ------------------------------------------------------------------- */
.section-intro {
	text-align: center;
	position: relative;
	margin-bottom: 1.5rem;
}

.section-intro h1 {
	font-family: "librebaskerville-bold", serif;
	font-size: 4.8rem;
	line-height: 1.375;
	color: #151515;
}

.section-intro h3 {
	font-family: "montserrat-bold", sans-serif;
	font-size: 2rem;
	color: #4C0404;
	text-transform: uppercase;
	letter-spacing: .15rem;
	margin-bottom: 0;
}

.section-intro.with-bottom-sep {
	padding-bottom: 4.2rem;
	position: relative;
}

.section-intro.with-bottom-sep::after {
	display: inline-block;
	height: 1px;
	width: 240px;
	background-color: #dedede;
	text-align: center;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * section-intro
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	.section-intro h1 {
		font-size: 4.2rem;
	}

	.section-intro h3 {
		font-size: 1.8rem;
	}

}

@media only screen and (max-width:600px) {
	.section-intro h1 {
		font-size: 3.6rem;
	}

	.section-intro h3 {
		font-size: 1.7rem;
	}

}

@media only screen and (max-width:400px) {
	.section-intro h1 {
		font-size: 3.3rem;
	}

	.section-intro h3 {
		font-size: 1.6rem;
	}

}

.wide {
	max-width: 1300px;
}

.narrow {
	max-width: 800px;
}

.js .animate-this,
.js .animate-intro {
	opacity: 0;
}

.oldie .animate-this,
.oldie .animate-intro
.no-cssanimations .animate-this,
.no-cssanimations .animate-intro {
	opacity: 1;
}


/* ===================================================================
 *  08. header styles - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
header {
	width: 100%;
	height: auto;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 700;
}

header .row {
	height: auto;
	max-width: 1300px;
	position: relative;
}

/* ------------------------------------------------------------------- 
 * header logo - (_layout.css) 
 * ------------------------------------------------------------------- */
header .header-logo {
	position: absolute;
	left: 26px;
	top: 30px;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	-webkit-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

header .header-logo a {
	display: block;
	margin: 0;
	padding: 0;
	outline: 0;
	border: none;
	width: 60px;
	height: 60px;
	background: url("./../../public/images/logo.png") no-repeat center;
	background-size: 60px 60px;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

/* ------------------------------------------------------------------- 
 * menu trigger - (_layout.css) 
 * ------------------------------------------------------------------- */
#header-menu-trigger {
	display: block;
	position: fixed;
	right: 26px;
	top: 30px;
	height: 42px;
	width: 42px;
	line-height: 42px;
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.4rem;
	text-transform: uppercase;
	letter-spacing: .2rem;
	color: rgba(255, 255, 255, 0.5);
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#header-menu-trigger.opaque {
	background-color: #000000;
	cursor: pointer;
}

#header-menu-trigger.opaque .header-menu-text {
	background-color: #000000;
}

#header-menu-trigger:hover,
#header-menu-trigger:focus {
	color: #FFFFFF;
}

#header-menu-trigger .header-menu-text {
	display: block;
	position: absolute;
	top: 0;
	left: -75px;
	width: 75px;
	text-align: center;
	background-color: transparent;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#header-menu-trigger .header-menu-icon {
	display: block;
	height: 2px;
	width: 24px;
	background-color: #FFFFFF;
	position: absolute;
	top: 50%;
	right: 9px;
	margin-top: -2px;
}

#header-menu-trigger .header-menu-icon::before,
#header-menu-trigger .header-menu-icon::after {
	content: '';
	width: 100%;
	height: 100%;
	background-color: inherit;
	position: absolute;
	left: 0;
}

#header-menu-trigger .header-menu-icon::before {
	top: -9px;
}

#header-menu-trigger .header-menu-icon::after {
	bottom: -9px;
}

/* ------------------------------------------------------------------- 
 * off-canvas menu - (_layout.scss) 
 * ------------------------------------------------------------------- */
#menu-nav-wrap {
	background: #111111;
	color: rgba(255, 255, 255, 0.25);
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.5rem;
	line-height: 1.6;
	padding: 3.6rem 3rem;
	height: 100%;
	width: 240px;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
	z-index: 800;
	overflow-y: auto;
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

#menu-nav-wrap a,
#menu-nav-wrap a:visited {
	color: rgba(255, 255, 255, 0.5);
}

#menu-nav-wrap a:hover,
#menu-nav-wrap a:focus,
#menu-nav-wrap a:active {
	color: white;
}

#menu-nav-wrap h3 {
	color: white;
	font-family: "montserrat-regular", sans-serif;
	font-size: 2rem;
	line-height: 1.5;
	margin-bottom: 1.5rem;
}

#menu-nav-wrap .nav-list {
	margin: 3.6rem 0 1.5rem 0;
	padding: 0 0 1.8rem 0;
	list-style: none;
	line-height: 3.6rem;
}

#menu-nav-wrap .nav-list li {
	padding-left: 0;
	border-bottom: 1px dotted rgba(255, 255, 255, 0.03);
	line-height: 4.2rem;
}

#menu-nav-wrap .nav-list li:first-child {
	border-top: 1px dotted rgba(255, 255, 255, 0.03);
}

#menu-nav-wrap .nav-list li a {
	color: rgba(255, 255, 255, 0.25);
	display: block;
	cursor: pointer;
}

#menu-nav-wrap .nav-list li a:hover,
#menu-nav-wrap .nav-list li a:focus {
	color: white;
}

#menu-nav-wrap .header-social-list {
	list-style: none;
	display: inline-block;
	margin: 0;
	font-size: 2.1rem;
}

#menu-nav-wrap .header-social-list li {
	margin-right: 12px;
	padding-left: 0;
	display: inline-block;
}

#menu-nav-wrap .header-social-list li a {
	color: rgba(255, 255, 255, 0.15);
}

#menu-nav-wrap .header-social-list li a:hover,
#menu-nav-wrap .header-social-list li a:focus {
	color: white;
}

#menu-nav-wrap .header-social-list li:last-child {
	margin: 0;
}

#menu-nav-wrap .close-button {
	display: block;
	height: 30px;
	width: 30px;
	border-radius: 3px;
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 36px;
	right: 30px;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	cursor: pointer;
}

#menu-nav-wrap .close-button span::before,
#menu-nav-wrap .close-button span::after {
	content: "";
	display: block;
	height: 2px;
	width: 12px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 9px;
	margin-top: -1px;
}

#menu-nav-wrap .close-button span::before {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#menu-nav-wrap .close-button span::after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* menu is open */
.menu-is-open #menu-nav-wrap {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	visibility: visible;
	-webkit-overflow-scrolling: touch;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * header section 
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	header .header-logo {
		top: 27px;
	}

	header .header-logo a {
		width: 54px;
		height: 54px;
		background-size: 54px 54px;
	}

}

@media only screen and (max-width:400px) {
	#header-menu-trigger .header-menu-text {
		display: none;
	}

}

/* ===================================================================
 *  09. home - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#home {
	background: #151515;
	width: 100%;
	height: 100%;
	background-image: url("./../../public/images/intro-bg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	min-height: 804px;
	position: relative;
}

#home .gradient-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .2;
	background: -moz-linear-gradient(left, black 0%, black 20%, transparent 100%);  /* FF3.6-15 */
	background: -webkit-linear-gradient(left, black 0%, black 20%, transparent 100%);  /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, black 0%, black 20%, transparent 100%);  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);  /* IE6-9 */
}

#home .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .6;
	background-color: #000000;
}

.home-content-table {
	width: 100%;
	height: 100%;
	display: table;
	position: relative;
	top:50%;
	transform: translate(0, 50%);
}

.home-content-tablecell {
	display: table-cell;
	vertical-align: bottom;
	z-index: 500;
}

.home-content-tablecell .row {
	position: relative;
	padding-top: 4.2rem;
	padding-bottom: 15rem;
}

.home-content-tablecell h3 {
	font-family: "montserrat-bold", sans-serif;
	font-size: 2.2rem;
	color: rgba(255, 255, 255, 0.35);
	text-transform: uppercase;
	letter-spacing: .15rem;
	margin: 0 0 0 .3rem;
}

.home-content-tablecell h1 {
	font-family: "librebaskerville-bold", serif;
	font-size: 5.2rem;
	line-height: 1.5;
	color: #FFFFFF;
}

.home-content-tablecell .home-section-links {
	display: inline-block;
	list-style: none;
	margin: 4.8rem 0 0 0;
}

.home-content-tablecell .home-section-links li {
	display: inline-block;
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.4rem;
	text-transform: uppercase;
	letter-spacing: .1rem;
	color: rgba(255, 255, 255, 0.35);
	margin-right: 1.5rem;
	margin-bottom: .6rem;
}

.home-content-tablecell .home-section-links li:last-child {
	margin-right: 0;
}

.home-content-tablecell .home-section-links a {
	font-family: "librebaskerville-regular", serif;
	font-size: 1.8rem;
	text-transform: none;
	letter-spacing: 0;
	color: #FFFFFF;
	margin-left: .3rem;
	cursor: pointer;
}

.scroll-icon {
	position: absolute;
	width: 60px;
	height: 48px;
	font-size: 3.3rem;
	bottom: 30px;
	right: 20px;
	color: #FFFFFF !important;
	-webkit-animation: vertical 3s ease infinite;
	animation: vertical 3s ease infinite;
}

/* vertical animation */
@-webkit-keyframes vertical {
	0%,
	60%,
	80%,
	100% {
		-webkit-transform: translateY(0);
	}

	20% {
		-webkit-transform: translateY(-5px);
	}

	40% {
		-webkit-transform: translateY(20px);
	}

}

@keyframes vertical {
	0%,
	60%,
	80%,
	100% {
		-webkit-transform: translateY(0);
	}

	20% {
		-webkit-transform: translateY(-5px);
	}

	40% {
		-webkit-transform: translateY(20px);
	}

}

/* ------------------------------------------------------------------- 
 * responsive:
 * home section 
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1200px) {
	.home-content-tablecell h3 {
		font-size: 2.1rem;
	}

	.home-content-tablecell h1 {
		font-size: 5rem;
	}

}

@media only screen and (max-width:1024px) {
	.home-content-tablecell h3 {
		font-size: 2rem;
	}

	.home-content-tablecell h1 {
		font-size: 4.8rem;
	}

}

@media only screen and (max-width:768px) {
	.home-content-tablecell .row {
		padding-bottom: 11.4rem;
	}

	.home-content-tablecell .home-bottom-text {
		padding-right: 15%;
	}

	.home-content-tablecell h3 {
		font-size: 1.8rem;
	}

	.home-content-tablecell h1 {
		font-size: 4rem;
	}

	.home-content-tablecell h1 br {
		display: none;
	}

	.home-content-tablecell .home-section-links {
		margin: 4.2rem 0 0 0;
	}

	.home-content-tablecell .home-section-links li {
		font-size: 1.3rem;
	}

	.home-content-tablecell .home-section-links a {
		font-size: 1.6rem;
	}

	.scroll-icon {
		right: 15px;
	}

}

@media only screen and (max-width:600px) {
	#home {
		min-height: 702px;
	}

	.home-content-tablecell {
		vertical-align: middle;
	}

	.home-content-tablecell .row {
		padding-top: 9rem;
		padding-bottom: 3rem;
	}

	.home-content-tablecell .home-bottom-text {
		padding-right: 10%;
	}

	.home-content-tablecell h3 {
		font-size: 1.7rem;
	}

	.home-content-tablecell h1 {
		font-size: 3.8rem;
	}

	.scroll-icon {
		right: 10px;
	}

}

@media only screen and (max-width:500px) {
	.home-content-tablecell .home-bottom-text {
		padding-right: 5%;
	}

	.home-content-tablecell h3 {
		font-size: 1.5rem;
	}

	.home-content-tablecell h1 {
		font-size: 3.4rem;
	}

}

@media only screen and (max-width:400px) {
	#home {
		min-height: 654px;
	}

	.home-content-tablecell .home-bottom-text {
		padding-right: 0;
	}

	.home-content-tablecell h3 {
		font-size: 1.4rem;
	}

	.home-content-tablecell h1 {
		font-size: 3.2rem;
	}

	.home-content-tablecell .home-section-links {
		margin: 1.5rem 0 0 0;
	}

}

/* ===================================================================
 *  10. about - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#about {
	position: relative;
	padding: 10.8rem 0 10.8rem;
	background-color: #FFFFFF;
	min-height: 700px;
}

#about h3 {
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.6rem;
	color: #4C0404;
	text-transform: uppercase;
	letter-spacing: .15rem;
	margin: 0 0 0 .3rem;
}

#about h1 {
	font-family: "librebaskerville-bold", serif;
	font-size: 3.3rem;
	line-height: 1.455;
}

.about-profile-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #EBEBEB;
	width: 50%;
	background-image: url("./../../public/images/profile-bg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	opacity: .35;
}

.about-wrap {
	width: 94%;
	max-width: 1300px;
	margin: 0 auto;
}

.about-wrap:before,
.about-wrap:after {
	content: "";
	display: table;
}

.about-wrap:after {
	clear: both;
}

.about-profile,
.about-services {
	float: left;
	width: 50%;
	padding-left: 20px;
	padding-right: 20px;
}

.about-profile {
	padding-right: 9rem;
	position: relative;
	z-index: 2;
}

.about-profile p.lead {
	color: #636363;
}

.about-profile .about-profile-bg {
	display: none;
}

.about-services {
	padding-left: 9rem;
	position: relative;
}

.about-services p.lead {
	color: #757575;
}

.about-services ul.services {
	list-style: none;
	margin-left: 0;
}

.about-services ul.services li {
	padding-left: 0;
	margin-bottom: .6rem;
	position: relative;
}

.about-services ul.services li h4 {
	font-size: 1.6rem;
	text-transform: uppercase;
	letter-spacing: .15rem;
	line-height: 1.313;
	margin-bottom: .6rem;
}

.about-services ul.services li p {
	margin-bottom: 1.5rem;
	color: #4f4f4f;
}

.about-services ul.services .icon-wrap {
	position: absolute;
	top: 0;
	left: 0;
	color: rgba(46, 22, 3, 0.4);
	color: #4C0404;
}

.about-services ul.services .icon-wrap i {
	font-size: 5rem;
}

.about-services ul.services .item-photography .icon-wrap {
	top: -.3rem;
}

.about-services ul.services .item-webdesign .icon-wrap {
	top: -.7rem;
}

.about-services ul.services .item-uiux .icon-wrap {
	top: -.1rem;
}

.about-services ul.services .service-item {
	padding-left: 8rem;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * about 
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1024px) {
	.about-profile {
		padding-right: 5rem;
	}

	.about-services {
		padding-left: 5rem;
	}

}

@media only screen and (max-width:900px) {
	.about-profile {
		padding-right: 4rem;
	}

	.about-services {
		padding-left: 4rem;
	}

}

@media only screen and (max-width:768px) {
	#about {
		padding-top: 0;
	}

	.about-wrap {
		padding: 0;
		width: 100%;
	}

	.about-profile-bg {
		display: none;
	}

	.about-profile,
	.about-services {
		float: none;
		width: 100%;
		padding-top: 10.8rem;
		padding-left: 45px;
		padding-right: 45px;
	}

	.about-profile .intro,
	.about-services .intro {
		text-align: center;
	}

	.about-profile {
		padding-bottom: 9rem;
	}

	.about-profile .about-profile-bg {
		display: block;
		width: 100%;
	}

}

@media only screen and (max-width:600px) {
	.about-profile,
	.about-services {
		padding-left: 35px;
		padding-right: 35px;
	}

}

@media only screen and (max-width:400px) {
	.about-profile,
	.about-services {
		padding-left: 25px;
		padding-right: 25px;
	}

	.about-services {
		text-align: center;
	}

	.about-services ul.services .icon-wrap {
		position: static;
		margin-bottom: .6rem;
	}

	.about-services ul.services .icon-wrap i {
		font-size: 6rem;
	}

	.about-services ul.services .service-item {
		padding-left: 0;
		margin-bottom: 3rem;
	}

	.about-services ul.services .service-item h4 {
		margin-bottom: 1.5rem;
	}

}

/* ===================================================================
 *  11. skills - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#skills {
	background-color: #1f0e01;
	background-image: url("./../../public/images/skills-bg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	min-height: 800px;
	position: relative;
	padding: 12rem 0 9.6rem;
	color: rgba(255, 255, 255, 0.3);
}

#skills .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .7;
	background-color: #000000;
}

#skills .gradient-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .2;
	background: -moz-linear-gradient(left, black 0%, black 20%, transparent 100%);  /* FF3.6-15 */
	background: -webkit-linear-gradient(left, black 0%, black 20%, transparent 100%);  /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, black 0%, black 20%, transparent 100%);  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);  /* IE6-9 */
}

#skills .row {
	position: relative;
}

#skills [class="col-skills"] {
	width: 50%;
	padding-right: 0;
}

#skills .section-intro {
	text-align: left;
}

#skills .section-intro h3 {
	color: rgba(255, 255, 255, 0.35);
	color: #7C0808;
}

#skills .section-intro h1 {
	color: #FFFFFF;
}

#skills .skill-bars {
	margin-top: 8.4rem;
}

#skills .skill-bars li {
	background: #7C0808;
	margin-bottom: 7.2rem;
}

#skills .skill-bars li strong {
	color: #FFFFFF;
}

#skills .skill-bars li .progress {
	background: #FFFFFF;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * skills
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1024px) {
	#skills [class="col-skills"] {
		width: 75%;
	}

}

@media only screen and (max-width:768px) {
	#skills .section-intro {
		text-align: center;
	}

	#skills [class="col-skills"] {
		width: 100%;
		padding-right: 15px;
	}

}

@media only screen and (max-width:600px) {
	#skills [class="col-skills"] {
		padding-right: 10px;
	}

}

@media only screen and (max-width:400px) {
	#skills [class="col-skills"] {
		padding-right: 0;
	}

}

/* ===================================================================
 *  12. credentials - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#credentials {
	background: #FFFFFF;
	position: relative;
	padding: 12rem 0 9rem;
}

#credentials .credentials-content h2 {
	margin-bottom: 4.8rem;
	color: #4C0404;
	padding-left: 8rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	letter-spacing: .15rem;
	position: relative;
}

#credentials .credentials-content h2::before {
	display: block;
	content: "";
	height: 3px;
	width: 4rem;
	background-color: #aeaeae;
	position: absolute;
	top: 50%;
	left: 2rem;
	margin-top: -3px;
}

#credentials .left {
	padding-right: 40px;
}

#credentials .right {
	padding-left: 40px;
}

#credentials .timeline-wrap {
	position: relative;
	text-align: left;
}

#credentials .timeline-wrap::before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	position: absolute;
	left: 2rem;
	top: 0;
}

#credentials .timeline-block {
	position: relative;
	padding-left: 8rem;
	margin-bottom: 4.2rem;
}

#credentials .timeline-ico {
	height: 4.8rem;
	width: 4.8rem;
	line-height: 4.8rem;
	background: #4C0404;
	border-radius: 50%;
	text-align: center;
	color: #FFFFFF;
	position: absolute;
	left: 2rem;
	top: 0;
	margin-left: -2.4rem;
}

#credentials .timeline-header .timeframe {
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.6rem;
	color: #757575;
	margin-bottom: 0;
}

#credentials .timeline-header h3 {
	font-size: 2.4rem;
	line-height: 1.25;
	margin-bottom: 0;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * credentials
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1024px) {
	#credentials .left {
		padding-right: 20px;
	}

	#credentials .right {
		padding-left: 20px;
	}

}

@media only screen and (max-width:768px) {
	#credentials .credentials-content h2 {
		padding: 0;
		text-align: center;
	}

	#credentials .credentials-content h2::before {
		display: none;
	}

	#credentials .left {
		padding-right: 10px;
	}

	#credentials .right {
		padding-left: 10px;
	}

}

@media only screen and (max-width:600px) {
	#credentials .timeline-block {
		padding-left: 7rem;
	}

}

@media only screen and (max-width:400px) {
	#credentials .timeline-header .timeframe {
		font-size: 1.5rem;
	}

	#credentials .timeline-header h3 {
		font-size: 2.2rem;
	}

	#credentials .timeline-block {
		padding-left: 6rem;
	}

}

/* ===================================================================
 *  13. portfolio - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#portfolio {
	background: #EBEBEB;
	min-height: 800px;
	position: relative;
	padding: 12rem 0 15rem;
}

#portfolio .bricks-wrapper:before,
#portfolio .bricks-wrapper:after {
	content: "";
	display: table;
}

#portfolio .bricks-wrapper:after {
	clear: both;
}

#portfolio .bricks-wrapper .brick {
	float: left;
	width: 50%;
	padding: 0;
	margin: 0;
}


/* ------------------------------------------------------------------- 
 * masonry entries - (_layout.css) 
 * ------------------------------------------------------------------- */
.bricks-wrapper .item-wrap {
	position: relative;
	overflow: hidden;
}

.bricks-wrapper .item-wrap .overlay {
	cursor: pointer;
}

.bricks-wrapper .item-wrap .overlay img {
	vertical-align: bottom;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.bricks-wrapper .item-wrap .overlay::before {
	content: "";
	display: block;
	background: rgba(0, 0, 0, 0.8);
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	z-index: 1;
}

.bricks-wrapper .item-wrap .overlay::after {
	content: "+";
	font-family: sans-serif;
	font-size: 2.4rem;
	z-index: 1;
	display: block;
	height: 30px;
	width: 30px;
	line-height: 30px;
	margin-left: -15px;
	margin-top: -15px;
	position: absolute;
	left: 50%;
	top: 50%;
	text-align: center;
	color: #FFFFFF;
	opacity: 0;
	visibility: hidden;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-transform: scale(0.5);
	-ms-transform: scale(0.5);
	transform: scale(0.5);
}

.bricks-wrapper .item-wrap .item-text {
	position: absolute;
	top: 0;
	left: 0;
	padding: 3rem 0 0 3rem;
	margin-right: 5rem;
	z-index: 3;
}

.bricks-wrapper .item-wrap .item-text .folio-title {
	color: #FFFFFF;
	font-size: 2.2rem;
	line-height: 1.364;
}

.bricks-wrapper .item-wrap .item-text .folio-types {
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.2rem;
	text-transform: uppercase;
	letter-spacing: .1rem;
	color: rgba(255, 255, 255, 0.7);
}

.bricks-wrapper .item-wrap .details-link {
	display: block;
	background-color: #FFFFFF;
	height: 4.6rem;
	width: 4.6rem;
	line-height: 4.6rem;
	color: #000000;
	text-align: center;
	z-index: 2;
	position: absolute;
	top: 3.6rem;
	right: 0;
}

.bricks-wrapper .item-wrap .details-link i {
	font-size: 2rem;
	line-height: 4.6rem;
}

.bricks-wrapper .item-wrap .details-link:hover,
.bricks-wrapper .item-wrap .details-link:focus {
	background-color: #000000;
	color: #FFFFFF;
}

.bricks-wrapper .item-wrap .item-text,
.bricks-wrapper .item-wrap .details-link {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.bricks-wrapper .item-wrap:hover .overlay::before {
	opacity: 1;
	visibility: visible;
}

.bricks-wrapper .item-wrap:hover .overlay::after {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.bricks-wrapper .item-wrap:hover .overlay img {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.bricks-wrapper .item-wrap:hover .item-text,
.bricks-wrapper .item-wrap:hover .details-link {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.bricks_row{
	display: flex;
}

.bricks_collum{
	display: flex;
	flex-direction: column;
}

.bricks_row .bricks_collum .brick_item{
	background-color: blue;
	width: 100%;
}

/* ------------------------------------------------------------------- 
 * Light Gallery Overrides - (_layout.css) 
 * ------------------------------------------------------------------- */
.lg-sub-html {
	padding: 12px 20% 15px;
}

.lg-sub-html a:hover,
.lg-sub-html a:focus {
	color: #FFFFFF;
}

@media only screen and (max-width:1024px) {
	.lg-sub-html {
		padding: 12px 15% 15px;
	}

}

@media only screen and (max-width:768px) {
	.lg-sub-html {
		padding: 12px 40px 15px;
	}
	.bricks_row{
		flex-direction: column;
	}

}

.lg-sub-html h4 {
	color: #FFFFFF;
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.8rem;
	line-height: 1.333;
}

.lg-sub-html p {
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.3rem;
	line-height: 2.4rem;
	margin: .6rem 0 0;
	color: rgba(255, 255, 255, 0.6);
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
	border-color: #7C0808;
}

.lg-progress-bar .lg-progress {
	background-color: #FFFFFF;
}

#lg-counter {
	font-family: "montserrat-regular", sans-serif;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * portfolio
 * ------------------------------------------------------------------- */
@media only screen and (max-width:600px) {
	#portfolio .bricks-wrapper .brick {
		float: none;
		width: 100%;
	}

}


/* ===================================================================
 *  14. stats - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#stats {
	background-color: #111111;
	position: relative;
	padding: 6rem 0 6rem;
}

#stats .stat {
	text-align: center;
	border-right: 1px solid rgba(255, 255, 255, 0.04);
}

#stats .stat:last-child {
	border: none;
}

#stats .stat .icon-part {
	color: #333;
}

#stats .stat .icon-part i {
	font-size: 6rem;
}

#stats .stat h5 {
	color: #FFFFFF;
	margin-top: 1.5rem;
	font-size: 1.4rem;
}

#stats .stat h3 {
	color: #7C0808;
	font-size: 4.8rem;
	text-transform: uppercase;
	letter-spacing: .2rem;
	margin: 1.5rem 0 0 0;
	padding-top: 1.5rem;
	line-height: 1;
	font-family: "montserrat-bold", sans-serif;
	position: relative;
	text-align: center;
}

#stats .stat h3::before {
	content: "";
	display: inline-block;
	width: 80px;
	height: 3px;
	background-color: rgba(5, 100, 139, 0.1);
	background-color: rgba(255, 255, 255, 0.1);
	text-align: center;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -40px;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * stats
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	#stats .stat:nth-child(n) {
		border: none;
		padding-bottom: 2.4rem;
	}

}


/* ===================================================================
 *  15. contact - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#contact {
	background-color: #111111;
	background-image: url("./../../public/images/profile-bg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	padding-top: 10.2rem;
	padding-bottom: 6rem;
	position: relative;
}

#contact .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .7;
	background-color: #000000;
}

#contact .section-intro h1 {
	color: white;
}

#contact .section-intro h3 {
	color: #7C0808;
}

#contact .section-intro p.lead {
	color: rgba(255, 255, 255, 0.3);
}

/* contact form */
.contact-form {
	max-width: 740px;
	margin-top: 4.2rem;
	position: relative;
}

.contact-form::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.contact-form:-moz-placeholder {
	color: rgba(255, 255, 255, 0.7);  /* Firefox 18- */
}

.contact-form::-moz-placeholder {
	color: rgba(255, 255, 255, 0.7);  /* Firefox 19+ */
}

.contact-form:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.contact-form .placeholder {
	color: rgba(255, 255, 255, 0.7) !important;
}

#contact form {
	margin-top: 0;
	margin-bottom: 3rem;
}

#contact form .form-field {
	position: relative;
}

#contact form .form-field:before,
#contact form .form-field:after {
	content: "";
	display: table;
}

#contact form .form-field:after {
	clear: both;
}

#contact form .form-field label {
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.1rem;
	line-height: 2.4rem;
	position: absolute;
	bottom: -1.2rem;
	right: .6rem;
	text-transform: uppercase;
	letter-spacing: .1rem;
	padding: 0 2rem;
	margin: 0;
	color: #FFFFFF;
	background: #4C0404;
}

#contact form .form-field label::after {
	position: absolute;
	left: -5px;
	top: 50%;
	margin-top: -6px;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-right: 5px solid #4C0404;
	content: "";
}

#contact input[type="text"],
#contact input[type="password"],
#contact input[type="email"],
#contact textarea {
	width: 100%;
	color: rgba(255, 255, 255, 0.7);
	margin-bottom: 0;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#contact input[type="text"],
#contact input[type="password"],
#contact input[type="email"] {
	height: 6.6rem;
	padding: 1.8rem 2rem;
}

#contact input[type="text"]:focus,
#contact input[type="password"]:focus,
#contact input[type="email"]:focus {
	border-color: #4C0404;
	color: #FFFFFF;
}

#contact textarea {
	min-height: 20rem;
	padding: 1.8rem 2rem;
}

#contact textarea:focus {
	border-color: #4C0404;
	color: #FFFFFF;
}

#contact button.submitform {
	font-size: 1.5rem;
	display: block;
	letter-spacing: .2rem;
	height: 6.6rem;
	line-height: 6.6rem;
	padding: 0 3rem;
	margin-top: 4.8rem;
	width: 100%;
	background: #7C0808;
	color: #FFFFFF;
}

#contact button.submitform:hover,
#contact button.submitform:focus {
	background: #4C0404;
}

#message-warning,
#message-success {
	display: none;
	background: black;
	padding: 3rem;
	margin-bottom: 3.6rem;
	width: 100%;
}

#message-warning {
	color: #D32B2E;
}

#message-success {
	color: #7C0808;
}

#message-warning i,
#message-success i {
	margin-right: 10px;
}

/* form loader */
#submit-loader {
	display: none;
	position: relative;
	left: 0;
	top: 1.8rem;
	width: 100%;
	text-align: center;
	margin-bottom: 4.2rem;
}

#submit-loader .text-loader {
	display: none;
	font-family: "montserrat-bold", sans-serif;
	color: #FFFFFF;
	letter-spacing: .2rem;
	text-transform: uppercase;
}

.oldie #submit-loader .s-loader {
	display: none;
}

.oldie #submit-loader .text-loader {
	display: block;
}

.contact-info {
	margin: 4.8rem auto 0;
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.6rem;
	text-align: center;
	position: relative;
}

.contact-info p {
	color: #555;
}

.contact-info .collapse {
	padding: 0;
}

.contact-info .icon {
	margin-bottom: 2.1rem;
}

.contact-info .icon i {
	font-size: 4.2rem;
	color: #FFFFFF;
}

.contact-info h5 {
	color: #FFFFFF;
}

/* --------------------------------------------------------------- 
 * loader animation - (_layout.scss)
 * --------------------------------------------------------------- */
.s-loader {
	margin: 1.2rem auto 3rem;
	width: 70px;
	text-align: center;
	-webkit-transform: translateX(0.45rem);
	-ms-transform: translateX(0.45rem);
	transform: translateX(0.45rem);
}

.s-loader > div {
	width: .6rem;
	height: .6rem;
	background-color: #FFFFFF;
	border-radius: 100%;
	display: inline-block;
	margin-right: .9rem;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.s-loader .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.s-loader .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

}

/* ===================================================================
 *  16. footer - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
footer {
	padding-top: 4.2rem;
	padding-bottom: 4.2rem;
	text-align: center;
	font-family: "montserrat-regular", sans-serif;
	font-size: 1.6rem;
	position: relative;
}

footer .footer-social {
	list-style: none;
	display: inline-block;
	margin-left: 0;
	margin-bottom: 9rem;
}

footer .footer-social li {
	display: inline-block;
	padding-left: 0;
	margin: 0 8px 1.5rem 8px;
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.8rem;
}

footer .footer-social li a,
footer .footer-social li a:visited {
	color: rgba(255, 255, 255, 0.5);
}

footer .footer-social li a:hover,
footer .footer-social li a:focus {
	color: #FFFFFF;
}

footer .footer-social li:last-child {
	margin-right: 0;
}

#go-top {
	position: fixed;
	bottom: 42px;
	right: 30px;
	z-index: 700;
	display: none;
}

#go-top a {
	text-decoration: none;
	border: 0 none;
	display: block;
	height: 63px;
	width: 60px;
	line-height: 63px;
	text-align: center;
	background-color: #000000;
	color: #888;
	text-align: center;
	text-transform: uppercase;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#go-top a i {
	font-size: 21px;
	line-height: inherit;
}

#go-top a:visited {
	background: #000000;
	color: #888;
}

#go-top a:hover,
#go-top a:focus {
	background: #000000;
	color: #FFFFFF;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * footer
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	#go-top {
		bottom: 0;
		right: 0;
	}

}
/* ------------------------------------------------------------------- 
 * responsive:
 * Personalizada
 * ------------------------------------------------------------------- */
.turn_button{
	width: 100%;
	max-width: 545px;
	height: 25vw;
	margin-bottom: 40px;
	background-size: auto 100%;
	transition: 0.3s;
	background-repeat: no-repeat;
	background-position: center;
}
.turn_button:hover{
	background-size: auto 120%;
}
.turn_button .overlay{
	background-color: rgba(0,0,0,0.7);
	width: 100%;
	height: 100%;
	color: white;
	text-align: center;
	line-height: 25vw;
	font-size: 25px;
	opacity: 0;
	transition: 0.3s;
	cursor: pointer;
}
.turn_button .overlay:hover{
	opacity: 1;
}
@media only screen and (max-width:768px) {
	.turn_button{height: 45vw;}
	.turn_button .overlay{line-height: 45vw;}
}